<template lang="pug">
Navbar
.bg-image
  .padding-top
    .container.d-none.d-md-block
      nav(aria-label='breadcrumb')
      ol.breadcrumb
        li.breadcrumb-item
          router-link.text-black.text-decoration-none(to='home') Home
        li.breadcrumb-item.active(aria-current='page') Advertisement tool
    HeadingLayout(:title="title", :desc="desc")
.mb-5
  template(v-for="message in messages")
    FeatureList(:title="message.title", :desc="message.desc", :image="message.image")
Footer
</template>

<script>
import Navbar from "../../components/TheNavbar";
import Footer from "../../components/TheFooter";
import FeatureList from "../../components/FeatureList";
import HeadingLayout from "../../layout/HeadingLayout";
export default {
  name: "AdvertisementTool",
  components: { HeadingLayout, FeatureList, Footer, Navbar },
  data() {
    return {
      title: "The simplest Marketing advertisement tool",
      desc: "Maximise your business by driving traffic and bringing visitors to your site with ads. Kloser has an easy-to-use Google Ad, Facebook Ad, Instagram Ad creation tool, automate ads set up and performance reporting",
      messages: [
        {
          image: "/images/features/ads/Ads_USP_Image_1.png",
          title: "Multiple platform ads dashboard in a page",
          desc: "You can have all your ads performance from various platforms at a glance. The dashboard indicates which is the best performance platform and you can top up easily. The returns of each ad are listed in the simplest format.",
        },
        {
          image: "/images/features/ads/Ads_USP_Image_2.png",
          title: "Ads tool for dummies",
          desc: "You don’t have to be a marketing expert to set up ads on Google, Instagram, Facebook. We make it straight forward for you. All you need is just fill up a few important entries, Kloser will settle the rest.",
        },
        {
          image: "/images/features/ads/Ads_USP_Image_3.png",
          title: "Lowest entry to advertise your business",
          desc: "Yes, you heard us right! You advertise via Kloser with a budget from RM250 for 7 days. You can choose the duration that fits your business goal and budget.",
        },
      ],
    };
  },
};
</script>
